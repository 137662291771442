.content {
  .login {
    height: 2.25rem;
    border-radius: 1.125rem;
    padding: 0 1.5rem;
    color: var(--app-text-color);
    background-color: var(--app-primary-color);
    font-size: 0.875rem;
  }

  .loginDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--app-text-color);

    > .creditDiv {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 34px;

      > .creditLabel {
        display: inline-block;
      }

      > .creditCount {
        color: var(--app-primary-color);
        margin-left: 5px;
      }
    }

    .emailIcon {
      height: 2.25rem;
      aspect-ratio: 1;
      border-radius: 1.125rem;
      padding: 0;
      margin: 0;
      background: #2db3ff;
      color: #fff;
      position: relative;

      > p {
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        aspect-ratio: 1;
        border-radius: 4px;
        background: red;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {

    .login {
      padding: 0 0.5rem;
      font-size: 0.7rem;
    }

    .loginDiv {

      > .creditDiv {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: 10px;

        > .creditLabel {
          display: none;
        }

        > .creditCount {
          margin-left: 0;
        }
      }
    }
  }
}

