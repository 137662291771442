.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  // position: relative;

  .collectContainer {
    // position: absolute;
    // top: 10px;
    right: 200px;
    width: 200px;
    height: 50px;
    background: red;
    border-radius: 10px;
  }

  .header,
  .footer {
    position: relative;
    width: 100%;
  }
  .header {
    z-index: 999;
  }

  .main {
    background: var(--foreground-rgb);
    width: 100%;
    flex: 1 1;
    margin-top: calc(var(--pietra-global-header-heigth) - 2px);

    // &.mobile {
    //   margin-top: var(--pietra-global-mobile-header-heigth);
    // }

    // &.topBanner {
      // margin-top: calc(var(--pietra-global-header-heigth) + 45px);
    // }
  }

  .mobile {
    margin-top: var(--pietra-global-mobile-header-heigth);
  }

  .topBanner {
    margin-top: calc(var(--pietra-global-header-heigth) + 45px);
  }

  .hiddenTopBanner {
    margin-top: 0;
  }
}

