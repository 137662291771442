.mainBackContent {
  color: var(--app-text-color);
  padding: 0 0 2rem;
  overflow-x: hidden;
  background-color: var(--foreground-rgb);
  // background-size: 100% auto;
  // background-repeat: no-repeat;
  // background-image: url('/images/layouts/background-icon.png');
}

.mainContent {
  max-width: 1060px;
  padding: 1rem;
  margin: 0 auto;
}

