
.container {
  // padding: 20px 24px;
  // margin-top: 30px;
  
  > .topContainer {
    padding: 20px;
    color: var(--app-text-color);
    height: 100px;

    background-image: url('/images/hairstyle/first_register_top_bg.webp');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }

  > .bottomContainer {
    padding: 20px;

    > .firstBtn,
    > .secondBtn {
      margin-top: 15px;
      width: 100%;
      border: none;
      height: 44px;
      color: var(--app-text-color);
    }

    > .firstBtn {
      background: linear-gradient( 90deg, #7D4FFF 0%, #BD22E4 100%);
    }

    > .secondBtn {
      background: var(--foreground-rgb);
      border: 1px solid var(--app-text-color);
    }
  }

  // <div className={styles.container}>
  //       <div className={styles.topContainer}>
  //         <span>{title}</span>
  //       </div>
  //       <div className={styles.bottomContainer}>
  //         <div>{desc}</div>
  //         {btnTitle1 && <Button onClick={handleClick1}>{btnTitle1}</Button>}
  //         {btnTitle2 && <Button onClick={handleClick2}>{btnTitle2}</Button>}

  //       </div>
  //     </div>

}

