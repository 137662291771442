.globalFooterWarp {
  background: var(--foreground-rgb);
  padding-bottom: 65px;

  ul {
    list-style-type: none;
  }

  .line {
    border-top: 1px solid var(--app-text-60-color);
    padding-bottom: 50px;
    background-color: var(--foreground-rgb);
  }

  .mainConetent {
    // margin-top: 60px;
    display: grid;
    grid-template-columns: 280px 1fr;
    grid-column-gap: 30px;

    > .logConatiner {
      > p {
        margin-top: 16px;
        color: var(--app-text-60-color);
      }
    }

    > .otherConatiner {
      text-align: right;

      > .linkContainer {
        color: var(--app-text-color);
        display: flex;
        justify-content: space-between;

        > span {
          cursor: pointer;

          &:hover {
            color: var(--app-primary-color);
          }
        }
      }

      > .copyrightContainer {
        margin-top: 16px;
        font-size: 16px;
        color: var(--app-text-60-color);
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .globalFooterWarp {
    .mainConetent {
      grid-template-columns: 280px 1fr;
      grid-row-gap: 0;

      > .otherConatiner {
        grid-template-columns: 1fr 1fr 1.5fr;
        grid-row-gap: 0;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .globalFooterWarp {
    .mainConetent {
      grid-template-columns: 1fr;
      grid-row-gap: 30px;

      > .otherConatiner {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        text-align: left;

        > .linkContainer {
          flex-direction: column;
        }
      }
    }
  }
}

